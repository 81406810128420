import Text from 'components/Text';
import React, { FC } from 'react';
import styled from 'styled-components';

interface VideoCardProps {
  video: string;
  title: string;
  description: string;
  reviewer: string;
}

const VideoReviewsCard: FC<VideoCardProps> = ({
  video,
  title,
  description,
  reviewer,
}) => (
  <StyledCard>
    <video width="258" height="288" autoPlay playsInline muted loop>
      <source src={video} type="video/mp4" />
    </video>
    <StyledReview>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Name>{reviewer}</Name>
    </StyledReview>
  </StyledCard>
);

export default VideoReviewsCard;

const StyledCard = styled.div`
  width: 258px;
  border-radius: 0.75rem;
  border: 1px solid #f3f3f8;
  background: #fbfbff;
  overflow: hidden;
  video {
    object-fit: cover;
  }
`;

const StyledReview = styled.div`
  display: flex;
  width: 100%;
  max-width: 258px;
  min-height: 144px;
  padding: 1rem 0.75rem 0.75rem;
  flex-direction: column;
`;

const Title = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  padding-bottom: 0.5rem;
`;

const Description = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  padding-bottom: 0.75rem;
`;

const Name = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
`;
