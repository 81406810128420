import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';

import VerifiedIcon from 'assets/icons/reviews/verified.svg';
import { DynamicImage } from 'components/images/DynamicImage';
import Text from 'components/Text';
import { mobile } from 'styles/breakpoints';

interface SuccessCardProps {
  imgUrl: string;
  story: string;
  author: string;
}

const SuccessCard: FC<SuccessCardProps> = ({ imgUrl, story, author }) => (
  <CardContainer>
    <Img
      src={imgUrl}
      alt="A smiling girl holding a book and sitting next to her dog"
    />

    <UserRatingContainer>
      <UserName>
        {author}
        <Svg>
          <VerifiedIcon />
        </Svg>
      </UserName>
      <StarsContainer>
        <StarIcons starCount={5} />
      </StarsContainer>
    </UserRatingContainer>
    <StyledText>{story}</StyledText>
  </CardContainer>
);

export default SuccessCard;

const StyledText = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0 0.75rem 0.75rem;
`;

const UserRatingContainer = styled.div`
  padding: 0.75rem 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Svg = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.375rem;
`;

const UserName = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
`;

const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Img = styled(DynamicImage)`
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20.125rem;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid #f2f2f5;
  background: #fbfbff;
  overflow: hidden;
  @media ${mobile} {
    max-width: unset;
  }
`;
