import React, { FC } from 'react';
import styled from 'styled-components';
import { useRouter } from 'apis/history';
import PrimaryButton from 'components/PrimaryButton';

interface CtaButtonProps {
  buttonText: string;
}

const CtaButton: FC<CtaButtonProps> = ({ buttonText }) => {
  const { goToLanding } = useRouter();

  return (
    <ButtonContainer onClick={() => goToLanding()}>
      <StyledCTA>{buttonText}</StyledCTA>
    </ButtonContainer>
  );
};

export default CtaButton;

const ButtonContainer = styled.div`
  width: 100%;
`;

const StyledCTA = styled(PrimaryButton)``;
