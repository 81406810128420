import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedIcon from 'assets/icons/reviews/verified.svg';
import { tablet } from 'styles/breakpoints';
import { DynamicImage } from 'components/images/DynamicImage';
import Text from 'components/Text';

const ReviewCard: FC = () => (
  <CardContainer>
    <StyledImage
      src="reviews/r1.png"
      alt="Happy woman with a Lasting Change book"
    />
    <UserRatingContainer>
      <UserName>
        Johanna H., 25
        <Svg>
          <VerifiedIcon />
        </Svg>
      </UserName>
      <StarsContainer>
        <StarIcons starCount={5} />
      </StarsContainer>
    </UserRatingContainer>
    <StyledText>
      &quot;The advice is relatable and down to earth. It helped me finally
      start my diet and exercise routine without feeling overwhelmed. The book
      made it much easier to stick to my goals and see real progress in just a
      few weeks!&quot;
    </StyledText>
  </CardContainer>
);

export default ReviewCard;

const CardContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 21.4375rem;
  border-radius: 0.75rem;
  border: 1px solid #f3f3f8;
  overflow: hidden;
  margin-bottom: 2.5rem;
  @media ${tablet} {
    margin-bottom: 2rem;
  }
`;

const StyledImage = styled(DynamicImage)`
  width: 100%;
`;

const StyledText = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0 0.75rem 0.75rem;
`;

const UserRatingContainer = styled.div`
  padding: 0.75rem 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Svg = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.375rem;
`;

const UserName = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
`;

const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
