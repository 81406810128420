import React, { FC } from 'react';
import styled from 'styled-components';
import ReviewCard from 'components/reviews/ReviewCard';
import TrustpilotStars from 'components/reviews/TrustpilotStars';
import TrustpilotStarsPartial from 'components/reviews/TrustpilotStarsPartial';
import CtaButton from 'components/reviews/CtaButton';
import { SectionHeading, SectionSubheading } from 'pages/reviews';
import { tablet } from 'styles/breakpoints';

const MainReviewSection: FC = () => (
  <StyledSection>
    <SectionHeading>Our customers’ reviews</SectionHeading>
    <HappyUsersContainer>
      <SectionSubheading marginBottom="10px">
        Thousands of happy readers around the world
      </SectionSubheading>
      <RatingContainer>
        <StarContainer>
          <TrustpilotStars starCount={4} />
          <TrustpilotStarsPartial starCount={1} />
        </StarContainer>
        <RatingNumber>4.8</RatingNumber>
      </RatingContainer>
    </HappyUsersContainer>
    <div>
      <ReviewCard />
      <CtaButton buttonText="Start your journey" />
    </div>
  </StyledSection>
);

export default MainReviewSection;

export const StyledSection = styled.section`
  height: fit-content;
  width: 100%;
  background: #fbfbff;
  padding: 3.5rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${tablet} {
    padding: 1.5rem 1rem 2.5rem 1rem;
  }
`;

const HappyUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;

  @media ${tablet} {
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 0.25rem;
`;

const StarContainer = styled.div`
  display: flex;
  gap: 6.579px;
`;

const RatingNumber = styled.span`
  font-size: 12px;
  line-height: 21.7px;
  color: #000;
`;
