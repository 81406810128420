import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { videoReviews } from 'utils/videoReviews';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';

const VideoReviewsSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <StyledSection backgroundColor="#fff" marginTop="0px">
      <SectionHeading>Real experiences with Lasting Change</SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '2rem' : '2.5rem'}>
        Readers share their success stories
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 15 : 22}
        >
          {videoReviews.map((review, index) => (
            <SwiperSlide key={index}>
              <VideoReviewsCard
                key={index}
                video={review.url}
                title={review.title}
                description={review.description}
                reviewer={review.name}
              />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </StyledSection>
  );
};

export default VideoReviewsSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 260px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 20px !important;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #ebebeb;
    &-active {
      background-color: #804da1;
      width: 8px;
    }
  }
`;
