import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import { tablet } from 'styles/breakpoints';
import Text from 'components/Text';

interface UserReviewProps {
  author: string;
  comment: string;
}

const UserReviewCard: FC<UserReviewProps> = ({ author, comment }) => (
  <ReviewContainer>
    <Rating>
      <Name>{author}</Name>
      <div>
        <StarIcons starCount={5} />
      </div>
    </Rating>
    <Review>{comment}</Review>
  </ReviewContainer>
);

export default UserReviewCard;

const Name = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`;

const Review = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  padding-top: 0.625rem;
`;

const ReviewContainer = styled.div`
  display: flex;
  padding: 1rem;
  width: 100%;
  max-width: 20.125rem;
  min-height: 7.938rem;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0.75rem;
  border: 1px solid #f3f3f8;
  background: #fff;
`;

const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
