interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl: 'reviews/r2.png',
    story: `"The Lasting Change book helped me develop a morning yoga and an evening journaling routine. I am so happy about this switch in my motivation. I highly recommend it to anyone serious about self-improvement."`,
    author: 'Jill C., 36',
  },
  {
    imgUrl: 'reviews/r3.png',
    story:
      '"As someone new to personal development, I found the steps provided extremely helpful. This book guided me to create a daily gratitude list and it helped me overcome depression. I feel more in control of my life,  thank you."',
    author: 'Ana, 25',
  },
  {
    imgUrl: 'reviews/r4.png',
    story: `"I've read many personal development books, but this one stands out. I didn’t expect a book would help me deal with my disordered eating. It has helped me break my late-night snacking habit, replacing it with a healthier evening tea ritual."`,
    author: 'Tiffany S., 28',
  },
  {
    imgUrl: 'reviews/r5.png',
    story: `"Lasting Change is a great guide. Techniques like Eisenhower matrix boosted my productivity so much! I've been able to organize my work tasks using the book’s methods and it lead me to better time management and less stress."`,
    author: 'Hannah B., 26',
  },
  {
    imgUrl: 'reviews/r6.png',
    story:
      '“It completely revamped my approach to personal growth. I built a solid bedtime routine, reading and meditation, and it improved my sleep quality so much. The book made it much easier to integrate new, healthy habits into my life.”',
    author: 'Mia M., 24',
  },
  {
    imgUrl: 'reviews/r7.png',
    story:
      '"It’s been almost 5 months since I bought the Lasting Change and it helped so much with my confidence. I used to be insecure all the time, but I finally learned how to love myself more. I also gifted this book to my sister, she loved it too!”',
    author: 'Dorothy S., 26',
  },
];
